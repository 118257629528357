import React from 'react'
import { useNavigate } from 'react-router-dom'
function AppButton(props) {
	const navigate = useNavigate()
	return (
		<a
			className="btn btn-app"
			href={props.route}
			onClick={e => {
				e.preventDefault()
				navigate(props.route)
			}}
		>
			<i className={'fas ' + (props.classIcon || 'fa-heart')}></i> {props.text}
		</a>
	)
}
export default AppButton
