import React, { useEffect, useState } from 'react'
import Content from '../components/Content'
import { toast } from 'react-toastify'
import { getTradingBotStatus, useTradingBot } from '../apiServices'

function Trading() {
	useEffect(() => {
		const eventSource = new EventSource('/trading-log-stream', { withCredentials: true })
		eventSource.onopen = e => {
			console.log('connected to stream')
		}
		eventSource.onmessage = e => {
			let message = e.data
			let consoleBody = document.querySelector('.consolebody')
			consoleBody.innerHTML += `<p>${message}</p>`
			//scroll to bottom
			consoleBody.scrollTop = consoleBody.scrollHeight
		}
		return () => eventSource.close()
	}, [])
	const consoleChange = () => {
		const consoleBody = document.querySelector('.consolebody')
		if (consoleBody) consoleBody.scrollTop = consoleBody.scrollHeight
	}
	let updateBotStatus = () => {
		getTradingBotStatus().then(res => {
			setData(res.data)
		})
	}
	const [data, setData] = useState(() => {
		updateBotStatus()
	}, {})
	const OnBotClick = e => {
		e.preventDefault()
		let action = e.target.action.value
		toast.promise(useTradingBot({ action }).then(updateBotStatus), {
			pending: 'Enviando solicitud...',
			success: 'Hecho!',
			error: 'Error al enviar solicitud',
		})
	}
	return (
		<Content>
			<h2 className="text-center display-4 content-title">Trading</h2>
			<div className={'info-box bg-' + (data ? (data.running ? 'success' : 'danger') : 'secondary')}>
				<span className="info-box-icon">
					<i className={'far fa-thumbs-' + (data && data.running ? 'up' : 'down')} />
				</span>
				<div className="info-box-content">
					<h3 className="info-box-text">{data ? (data.running ? 'Running' : 'Not Running') : 'Pending'}</h3>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<form onSubmit={OnBotClick}>
					<button type="submit" name="action" value="start" className="btn btn-app">
						<i className="fa fa-play" /> Start
					</button>
				</form>
				<form onSubmit={OnBotClick}>
					<button type="submit" name="action" value="stop" className="btn btn-app">
						<i className="fa fa-stop" /> Stop
					</button>
				</form>
			</div>
			<div
				style={{
					width: '90%',
					justifyContent: 'center',
					margin: 'auto',
				}}
			>
				<header
					className="console rounded-top bg-secondary"
					style={{
						height: '45px',
						textAlign: 'center',
						lineHeight: '45px',
					}}
				>
					<p>Carual@trading</p>
				</header>
				<div
					className="consolebody rounded-bottom"
					style={{
						height: '450px',
						backgroundColor: 'black',
						color: '#63de00',
						padding: '20px',
						overflowY: 'scroll',
					}}
					onChange={consoleChange()}
				></div>
			</div>
		</Content>
	)
}

export default Trading
