import React, { createContext, useEffect, useState } from 'react'
import { getUserData } from '../apiServices'

// Create the UserContext
export const UserContext = createContext()

// Create a UserProvider component
export const UserProvider = ({ children }) => {
	// Define the state for the user
	const [userData, setUserData] = useState(null)
	const updateUser = () => {
		if (userData === null) {
			getUserData()
				.then(({ data }) => {
					setUserData(data)
					if (window.location.pathname === '/login') {
						window.location.href = '/'
					}
				})
				.catch(() => {})
		}
	}
	useEffect(updateUser, [])

	// Provide the user state and any related functions/methods to the children components
	return <UserContext.Provider value={{ userData, setUserData }}>{children}</UserContext.Provider>
}
