import React, { useState } from 'react'
import Content from '../components/Content'
import { getWhatsappBotStatus, useWhatsappBot } from '../apiServices'
import { toast } from 'react-toastify'
function WhatsappBot() {
	let updateBotStatus = () => {
		getWhatsappBotStatus().then(res => {
			setData(res.data)
		})
	}
	const [data, setData] = useState(() => {
		updateBotStatus()
	}, {})
	let OnBotClick = e => {
		e.preventDefault()
		const action = e.target.value
		toast.promise(
			useWhatsappBot({
				action,
			}),
			{
				pending: 'Enviando solicitud...',
				success: 'Hecho!',
				error: 'Error al enviar solicitud',
			},
		)
	}

	return (
		<Content>
			<h2 className="text-center display-4 content-title">Whastapp Bot</h2>
			<div className={'info-box bg-' + (data ? (data.running ? 'success' : 'danger') : 'secondary')}>
				<span className="info-box-icon">
					<i className={'far fa-thumbs-' + (data && data.running ? 'up' : 'down')} />
				</span>
				<div className="info-box-content">
					<h3 className="info-box-text">{data ? (data.running ? 'Running' : 'Not Running') : 'Pending'}</h3>
				</div>
			</div>
			<button type="submit" name="action" value="start" className="btn btn-app" onClick={OnBotClick}>
				<i className="fa fa-play" /> Start
			</button>
			<button type="submit" name="action" value="stop" className="btn btn-app" onClick={OnBotClick}>
				<i className="fa fa-stop" /> Stop
			</button>
		</Content>
	)
}

export default WhatsappBot
