import React from 'react'
import Footer from './Footer'
import Header from './Header'
import SideNav from './SideNav'

function Content({ children }) {
	return (
		<>
			<Header />
			<SideNav />
			<div className="content-wrapper">{children}</div>
			<Footer />
		</>
	)
}
export default Content
