import React from 'react'

function Card(props) {
	return (
		<div className="col-md-3">
			<div className={`card card-${props.invoice ? (props.invoice.state ? 'success' : 'danger') : 'secondary'} collapsed-card`}>
				<div className="card-header">
					<h3 className="card-title">{props.itemName}</h3>
					<div className="card-tools">
						<button type="button" className="btn btn-tool" data-card-widget="collapse">
							<i className="fas fa-plus" />
						</button>
					</div>
					{/* /.card-tools */}
				</div>
				{/* /.card-header */}
				<div className="card-body">{props.body}</div>
				{/* /.card-body */}
			</div>
			{/* /.card */}
		</div>
	)
}
export default Card
