import React, { useEffect } from 'react'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { getOauthUrls } from '../apiServices'

//import { useNavigate } from 'react-router-dom'
function Login() {
	const [urls, setUrls] = React.useState()
	useEffect(() => {
		getOauthUrls().then(({ data }) => {
			if (data) {
				console.log(data)
				setUrls(data)
			} else {
				toast.error(data.message)
			}
		})
	}, [])
	//let navigate = useNavigate()
	function handleSubmit(e) {
		e.preventDefault()
		Axios.post('/api/login', {
			username: e.target[0].value,
			password: e.target[1].value,
		})
			.then(({ data }) => {
				if (data.success) {
					window.location.href = '/'
				} else {
					toast.error(data.message)
				}
			})
			.catch(err => {
				toast.error(err.message)
			})
	}
	return (
		<div className="login-page dark-mode">
			<div className="login-box">
				{/* /.login-logo */}
				<div className="card card-outline card-primary">
					<div className="card-header text-center">
						<a href="/" className="h1">
							<b>Carual</b> Web
						</a>
					</div>
					<div className="card-body">
						<p className="login-box-msg">Inicia sesión</p>
						<form method="post" onSubmit={handleSubmit}>
							<div className="input-group mb-3">
								<input type="username" className="form-control" placeholder="Usuario" />
								<div className="input-group-append">
									<div className="input-group-text">
										<span className="fas fa-envelope" />
									</div>
								</div>
							</div>
							<div className="input-group mb-3">
								<input type="password" className="form-control" placeholder="Password" />
								<div className="input-group-append">
									<div className="input-group-text">
										<span className="fas fa-lock" />
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-8">
									<div className="icheck-primary">
										<input type="checkbox" id="remember" />
										<label htmlFor="remember"> Recuerdame</label>
									</div>
								</div>
								{/* /.col */}
								<div className="col-4">
									<button type="submit" className="btn btn-primary btn-block">
										Entrar
									</button>
								</div>
								{/* /.col */}
							</div>
						</form>
						<div className="social-auth-links text-center mt-2 mb-3">
							{urls
								? Object.values(urls).map(url => {
										console.log(url)
										return (
											<a
												href={url.url}
												className="btn btn-block"
												style={{
													backgroundColor: url.color,
													color: 'white',
												}}
											>
												<i className={'fab mr-2 fa-' + url.name} /> Entrar con {url.name.charAt(0).toUpperCase() + url.name.slice(1)}
											</a>
										)
								  })
								: undefined}
						</div>
						{/* /.social-auth-links */}
						<p className="mb-1">
							<a href="forgot-password.html">I forgot my password</a>
						</p>
						<p className="mb-0">
							<a href="register.html" className="text-center">
								Register a new membership
							</a>
						</p>
					</div>
					{/* /.card-body */}
				</div>
				{/* /.card */}
			</div>
			{/* /.login-box */}
			{/* jQuery */}
			{/* Bootstrap 4 */}
			{/* AdminLTE App */}
		</div>
	)
}

export default Login
