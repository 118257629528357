import React, { useState } from 'react'
import Content from '../components/Content'
import Card from '../components/Card'
import { getUserInvoices } from '../apiServices'
import { toast } from 'react-toastify'

async function copyToClipboard(text) {
	try {
		await navigator.clipboard.writeText(text)
		toast.success(`"${text}" copied to clipboard`)
	} catch (err) {
		console.error('Failed to copy: ', err)
	}
}

function InvoiceText({ invoice }) {
	return (
		<>
			<table className="table table-bordered">
				<tbody>
					<tr>
						<td>
							<b>Nombre</b>
						</td>
						<td>{invoice.UserInvoices.CustomName ? `${invoice.UserInvoices.CustomName} | ${invoice.InvoiceType.name}` : invoice.InvoiceType.name}</td>
					</tr>
					<tr>
						<td>
							<b>Cuenta</b>
						</td>
						<td>
							{invoice.account}
							<i
								style={{ cursor: 'pointer', paddingLeft: '0.5rem' }}
								className="fas fa-copy"
								onClick={() => {
									copyToClipboard(invoice.account)
								}}
							></i>
						</td>
					</tr>
					{invoice.amount ? (
						<tr>
							<td>
								<b>
									<u>Precio</u>
								</b>
							</td>
							<td>$ {invoice.amount.toLocaleString()}</td>
						</tr>
					) : undefined}
					<tr>
						<td>
							<b>Compañia</b>
						</td>
						<td>{invoice.InvoiceType.name}</td>
					</tr>
				</tbody>
			</table>
			<a
				href={invoice.InvoiceType.link}
				target="_blank"
				className="btn btn-primary btn-lg active"
				role="button"
				style={{
					margin: 'auto',
					display: 'block',
					textAlign: 'center',
					marginTop: '20px',
				}}
			>
				Pagina de pago
			</a>
		</>
	)
}
function Invoices() {
	const [invoices, setInvoices] = useState(() => {
		getUserInvoices()
			.then(({ data }) => {
				console.log(data)
				setInvoices(data)
			})
			.catch(() => {
				setInvoices([])
			})
	}, [])
	return (
		<Content>
			<h1
				className="text-center display-4 text-bold"
				style={{
					padding: '4%',
				}}
			>
				Facturas
			</h1>
			<div className="container-fluid">
				<div className="row">
					{invoices
						? invoices.map(invoice => (
								<Card
									key={invoice.id}
									itemName={
										invoice.UserInvoices.CustomName ? `${invoice.UserInvoices.CustomName} | ${invoice.InvoiceType.name}` : invoice.InvoiceType.name
									}
									invoice={invoice}
									body={<InvoiceText invoice={invoice} />}
								/>
						  ))
						: [0, 1, 2, 3].map(i => {
								return <Card key={i} itemName="Cargando..."></Card>
						  })}
				</div>
			</div>
		</Content>
	)
}

export default Invoices
