import React, { useContext } from 'react'
import Content from '../components/Content'
import { DirectoryContext } from '../context/DirectoryContext'
import AppButton from '../components/AppButton'
function Home() {
	const apps = useContext(DirectoryContext)
	return (
		<Content>
			<h1 className="text-center display-4 content-title">Inicio</h1>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'center',
				}}
			>
				{apps ? apps.map(app => <AppButton key={app.name} text={app.name} classIcon={app['fa-icon']} route={app.route} />) : false}
			</div>
		</Content>
	)
}

export default Home
