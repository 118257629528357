import Axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'

// Create the UserContext
export const DirectoryContext = createContext()

// Create a UserProvider component
export const DirectoryProvider = ({ children }) => {
	// Define the state for the user
	const [DirectoryData, setDirectory] = useState(null)
	useEffect(() => {
		if (DirectoryData === null) {
			Axios.get('/api/directory')
				.then(({ data }) => {
					setDirectory(data)
				})
				.catch(() => {})
		}
	}, [])

	// Provide the user state and any related functions/methods to the children components
	return <DirectoryContext.Provider value={DirectoryData}>{children}</DirectoryContext.Provider>
}
