import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import Home from './pages/Home'
import Login from './pages/Login'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { UserProvider } from './context/UserContext'
import { DirectoryProvider } from './context/DirectoryContext'
import Ecommerce from './pages/Ecommerce'
import Invoices from './pages/Invoices'
import WhatsappBot from './pages/whatsappBot.js'
import Trading from './pages/Trading'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './main.css'
import NotFound from './pages/NotFound.js'
const root = ReactDOM.createRoot(document.getElementById('root'))
const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
		loginRequired: true,
		title: 'Inicio',
	},
	{
		path: '/login',
		element: <Login />,
		loginRequired: false,
	},
	{
		path: '/ecommerce',
		element: <Ecommerce />,
	},
	{
		path: '/invoices',
		element: <Invoices />,
	},
	{
		path: '/trading',
		element: <Trading />,
	},
	{
		path: '/bot',
		element: <WhatsappBot />,
	},
	{
		path: '*',
		element: <NotFound />,
	},
])

root.render(
	<StrictMode>
		<UserProvider>
			<DirectoryProvider>
				<ToastContainer theme="dark" autoClose={2500} />
				<RouterProvider router={router}></RouterProvider>
			</DirectoryProvider>
		</UserProvider>
	</StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
