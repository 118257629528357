import React from 'react'

function Footer() {
	return (
		<footer className="main-footer">
			<strong>
				React version of <a href="https://carual.com"> Carual.com </a>
			</strong>
			Starter.
			<div className="float-right d-none d-sm-inline-block">
				<b>Version</b> 0.0.1
			</div>
		</footer>
	)
}
export default Footer
