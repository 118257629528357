import Axios from 'axios'

export async function getUserData() {
	return Axios.get('/api/me').catch(err => {
		errorHandler(err)
		throw new Error(err)
	})
}
export async function getOauthUrls() {
	return Axios.get('/auth/urls').catch(err => {
		errorHandler(err)
		throw new Error(err)
	})

}
export async function logout() {
	return Axios.post('/api/logout')
		.then(() => {
			window.location.href = '/login'
		})
		.catch(err => {
			errorHandler(err)
			throw new Error(err)
		})
}
export async function getUserInvoices() {
	return Axios.get('/api/facturas').catch(err => {
		errorHandler(err)
		throw new Error(err)
	})
}
export async function searchEcommerceProducts(searchObj) {
	return Axios.get('/api/ecommerce', { params: searchObj }).catch(err => {
		errorHandler(err)
		throw new Error(err)
	})
}
export async function getWhatsappBotStatus() {
	return Axios.get('/api/bot').catch(err => {
		errorHandler(err)
		throw new Error(err)
	})
}
export async function useWhatsappBot({ action }) {
	return Axios.post('/api/bot', { action }).catch(err => {
		errorHandler(err)
		throw new Error(err)
	})
}
export async function getTradingBotStatus() {
	return Axios.get('/api/trading-bot').catch(err => {
		errorHandler(err)
		throw new Error(err)
	})
}
export async function useTradingBot({ action }) {
	return Axios.post('/api/trading-bot', { action }).catch(err => {
		errorHandler(err)
		throw new Error(err)
	})
}
function errorHandler(err) {
	console.error(err)
	if (err.response.status === 401) {
		if (window.location.pathname !== '/login') {
			window.location.href = '/login'
		}
	}
	return err
}
