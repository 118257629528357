import React, { useState } from 'react'
import Content from '../components/Content'
import { searchEcommerceProducts } from '../apiServices'
import { Swiper, SwiperSlide } from 'swiper/react'
import Barcode from 'react-jsbarcode'
import { Pagination, Navigation } from 'swiper/modules'
import { toast } from 'react-toastify'
import { useZxing } from 'react-zxing'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
function StoreSection(props) {
	console.log('results', props)
	console.log(window.innerWidth)
	return (
		<div
			style={{
				boxShadow: '0 0 10px rgba(0,0,0,0.1)',
			}}
		>
			<div class="text-center">
				<img
					src={props.market.img}
					style={{
						maxHeight: '75px',
						borderRadius: '12px',
						margin: '20px',
					}}
					alt={props.market.name}
				/>
			</div>
			<Swiper
				slidesPerView={Math.round(window.innerWidth / 400)}
				spaceBetween={30}
				centeredSlides={props.results.length <= 1 ? true : false}
				pagination={{
					clickable: true,
				}}
				modules={[Pagination, Navigation]}
				navigation={true}
			>
				{props.results.map((result, i) => (
					<SwiperSlide>
						<div
							className="card rounded"
							style={{
								width: '80%',
								boxShadow: '0 0 10px rgba(0,0,0,0.1), 0 0 10px rgba(0,0,0,0.1)',
								margin: 'auto',
								marginBottom: '20px',
							}}
						>
							<img
								src={result.image}
								className="card-img-top rounded-top"
								alt="..."
								style={{
									height: '280px',
								}}
							/>
							<div
								className="card-body text-center"
								style={{
									textShadow: '0 0 10px rgba(0,0,0,0.1)',
								}}
							>
								{result.ean ? (
									<div
										class="text-center"
										style={{
											marginBottom: '10px',
											cursor: 'pointer',
										}}
										onClick={() => {
											try {
												navigator.clipboard.writeText(result.ean).then(() => {
													toast.success(`"${result.ean}" copied to clipboard`)
												})
											} catch (err) {
												console.error('Failed to copy: ', err)
											}
										}}
									>
										<Barcode
											value={result.ean}
											options={{
												width: 1.2,
												height: 30,
												fontSize: 17,
												margin: 8,
												marginBottom: 5,
											}}
										/>
									</div>
								) : undefined}
								<h5 className="card-title text-center">
									<b>{result.name}</b>
								</h5>
								<h3>
									<span
										className="badge bg-info"
										style={{
											width: '100%',
											padding: '12px',
											marginTop: '15px',
										}}
									>
										$ {result.price.toLocaleString()}
									</span>
								</h3>
								<h5 className="float-left">
									<span className="badge bg-success">{`${result.PUM.toLocaleString(undefined, {
										maximumFractionDigits: 2,
									})} $/${result.quantType.slice(0, 2)}`}</span>
								</h5>
								<h5 className="float-right">
									<span className="badge bg-info">{result.quant.toLocaleString() + ' ' + result.quantType.slice(0, 2)}</span>
								</h5>
								<a
									href={result.link}
									target="_blank"
									class="btn btn-primary"
									rel="noreferrer"
									style={{
										margin: '10px',
									}}
								>
									Ver en tienda
								</a>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	)
}

function Ecommerce() {
	const [isScanning, setIsScanning] = useState(false)
	const [results, setResults] = useState()
	const audio = new Audio('https://cdn.freesound.org/previews/611/611503_9839964-lq.mp3')
	const search = async search => {
		if (results && results.then) return console.log('results made')
		searchEcommerceProducts(search).then(res => {
			res.data.sort((a, b) => (a.results.length > 0 ? -1 : 1))
			setResults(res.data)
		})
	}
	const onSearch = e => {
		e.preventDefault()
		let searchTerm = e.target.search.value
		let order = e.target.order.value
		setResults(search({ search: searchTerm, order }))
	}

	const BarcodeScanner = () => {
		const { ref } = useZxing({
			onDecodeResult({ text }) {
				//get search input
				document.getElementById('ecommerce-search-bar').value = text
				toast.success(`Barcode detected: ${text}`)
				setIsScanning(false)
				audio.play()
				if (navigator.vibrate) navigator.vibrate(200)
				if (!isNaN(text)) {
					//click search button
					document.getElementById('ecommerce-search-button').click()
				}
			},
		})
		return (
			<div className="text-center">
				<video ref={ref} />
			</div>
		)
	}
	return (
		<Content>
			<h1
				className="text-center display-4 text-bold"
				style={{
					padding: '4%',
				}}
			>
				Ecommerce
			</h1>
			<form
				style={{
					display: 'flex',
					width: '100%',
					marginBottom: '50px',
				}}
				onSubmit={onSearch}
				id="ecommerce-search-form"
			>
				<div
					className="text-center"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						margin: 'auto',
						position: 'relative',
					}}
				>
					<div
						style={{
							width: '52vw',
							display: 'flex',
							marginBottom: '20px',
						}}
					>
						<div className="input-group-prepend">
							<button type="button" className="btn btn-warning" onClick={() => setIsScanning(true)}>
								<span className="fas fa-barcode"></span>
							</button>
						</div>
						<input className="form-control" type="text" placeholder="Ingrese Producto" name="search" id="ecommerce-search-bar" />

						<button type="submit" className="btn btn-primary btn-flat rounded" id="ecommerce-search-button">
							<span className="fas fa-search"></span>
						</button>
					</div>
					<div
						className="form-group text-center "
						style={{
							width: '200px',
							display: 'flex',
							alignItems: 'center',
							marginLeft: '1vw',
						}}
					>
						<label
							style={{
								marginRight: '5px',
							}}
						>
							Orden:
						</label>
						<select
							className="custom-select form-control-border form-control"
							name="order"
							style={{
								width: 'auto',
							}}
						>
							<option value="PUM">PUM</option>
							<option value="price">Precio</option>
							<option value="default">Relevancia</option>
						</select>
					</div>
				</div>
			</form>
			{isScanning ? <BarcodeScanner /> : undefined}
			{results && !results.then ? (
				results.map((result, i) => <StoreSection key={i} market={result.market} results={result.results} />)
			) : results && results.then ? (
				<div
					className="text-center"
					style={{
						margin: '20px',
					}}
				>
					<div
						className="spinner-grow text-dark"
						role="status"
						style={{
							width: '3rem',
							height: '3rem',
						}}
					>
						<span class="sr-only">Loading...</span>
					</div>
					<p>Cargando...</p>
				</div>
			) : undefined}
		</Content>
	)
}

export default Ecommerce
