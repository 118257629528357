import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

function SideNavItem(props) {
	const location = useLocation()
	const navigate = useNavigate()
	return (
		<li className="nav-item ">
			<a
				href={props.route}
				className={'nav-link' + (location.pathname === props.route ? ' active' : '')}
				onClick={e => {
					e.preventDefault()
					navigate(props.route)
				}}
			>
				<i className={'nav-icon fas ' + props.classIcon}></i>
				<p>
					{props.itemName} {props.isNew ? <span className="right badge badge-success">App</span> : undefined}
				</p>
			</a>
		</li>
	)
}
export default SideNavItem
